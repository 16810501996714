import { store } from '../store'

export default class AccountService {
    async getAccounts (params) {
        return await store.dispatch('account/getAccounts', {
            _data: params
        })
    }

    async getAccountsWithApiKeys (params) {
        return await store.dispatch('account/getAccountsWithApiKeys', {
            _data: params
        })
    }

    async showAccount (id) {
        return await store.dispatch('account/showAccount', {
            _params: {
                account: id
            }
        })
    }

    async updateAccount (id, data) {
        return await store.dispatch('account/updateAccount', {
            _params: {
                account: id
            },
            _data: data
        })
    }

    async storeAccount (data) {
        return await store.dispatch('account/storeAccount', {
            _data: data
        })
    }

    async deleteAccount (id) {
        return await store.dispatch('account/deleteAccount', {
            _params: {
                account: id
            }
        })
    }

    activeOptions () {
        return [
            { label: 'Активный', value: true },
            { label: 'Не активный', value: false }
        ]
    }
}
