<template>
    <div class="grid">
        <ConfirmPopup/>
        <div class="col-12">
            <ProgressBar
                v-if="dataLoading"
                mode="indeterminate"
                class="custom-progress-bar"/>
            <div class="flex flex-grow-1 justify-content-between mb-4 align-items-start">
                <h3 class="mb-0">Мои аккаунты и API ключи</h3>
                <Button
                    v-if="getAccountsWithApiKeys && getAccountsWithApiKeys.length"
                    label="Добавить аккаунт"
                    icon="pi pi-plus"
                    class="p-button p-button-sm"
                    @click="openAccountDialog"/>
            </div>
            <ApiKeySkeleton v-if="dataLoading && !getAccountsWithApiKeys"/>
            <Panel
                v-for="(account, key) in getAccountsWithApiKeys"
                :key="key"
                :class="['mb-4']">
                <template #header>
                    <div>
                        <span class="mr-4 font-bold">
                            <i class="pi pi-user mr-2"></i>
                            <span class="p-text-secondary capitalize">{{ account.exchange.name }} :</span>
                            {{ account.note || account.id }}
                        </span>
                        <Tag
                            rounded
                            :icon="account.is_active ? 'pi pi-check' : 'pi pi-exclamation-triangle'"
                            :severity="account.is_active ? 'success' : 'warn'"
                            :value="account.is_active ? 'Активный' : 'Не активный'"></Tag>
                    </div>
                </template>
                <template #icons>
                    <div>
                        <Button
                            icon="pi pi-pencil"
                            class="p-button-text p-button-success mr-2 p-button-sm"
                            @click="showAccount(account.id)"/>
                        <Button
                            v-if="account.api_keys && account.api_keys.length"
                            icon="pi pi-plus"
                            class="p-button-text mr-2 p-button-sm"
                            @click="openApiKeyDialog(account.id)"/>
                        <Button
                            icon="pi pi-trash"
                            v-if="!account.api_keys || !account.api_keys.length"
                            class="p-button-text p-button-danger mr-2 p-button-sm"
                            @click="confirmDeleteAccount($event, account.id)"/>
                    </div>
                </template>
                <template #default>
                    <DataTable
                        v-if="account.api_keys && account.api_keys.length"
                        :value="account.api_keys"
                        class="p-datatable-sm"
                        responsiveLayout="scroll">
                        <Column v-for="(col, index) of apiKeyColumns"
                                :field="col.field"
                                :header="col.header"
                                :key="col.field + '_' + index"
                                :style="{width : col.width }">
                            <template #body="{data}" v-if="col.field === 'public_key'">
                                <small>
                                    {{ data.public_key.substr(0, 7) }}...{{ data.public_key.substr(-7, 7) }}
                                </small>
                            </template>
                            <template #body="{data}" v-else-if="col.field === 'secret_key'">
                                <small>
                                    {{ data.secret_key.substr(0, 7) }}...{{ data.secret_key.substr(-7, 7) }}
                                </small>
                            </template>
                            <template #body="{data}" v-else-if="col.field === 'is_active'">
                                <Tag
                                    rounded
                                    :icon="data.is_active ? 'pi pi-check' : 'pi pi-exclamation-triangle'"
                                    :severity="data.is_active ? 'success' : 'warn'"
                                    :value="data.is_active ? 'Активный' : 'Не активный'"></Tag>
                            </template>
                            <template #body="{data}" v-else-if="col.field === 'color'">
                                <i class="pi pi-star-fill" :style="`color:${data.color ? data.color : defaultColor}`"></i>
                            </template>
                        </Column>
                        <Column bodyStyle="text-align:right">
                            <template #body="slotProps">
                                <Button
                                    icon="pi pi-pencil"
                                    class="p-button-text p-button-success mr-2 p-button-sm"
                                    @click="showApiKey($event, slotProps.data.id)"/>
                                <Button
                                    icon="pi pi-trash"
                                    class="p-button-text p-button-danger p-button-sm"
                                    @click="confirmDeleteApiKey($event, slotProps.data.id)"/>
                            </template>
                        </Column>
                    </DataTable>
                    <div
                        v-else
                        class="text-center">
                        <Button
                            icon="pi pi-plus"
                            label="Добавить ключ"
                            class="p-button mr-2 p-button-sm"
                            @click="openApiKeyDialog(account.id)"/>
                    </div>
                </template>
            </Panel>
        </div>
        <Dialog
            v-model:visible="apiKeyDialog"
            :style="{width: '450px'}"
            :modal="true"
            header="Информация о ключе"
            class="p-fluid">
            <div class="field">
                <label for="dropdown">Аккаунт</label>
                <Dropdown
                    id="dropdown"
                    v-model="apiKeyForm.fields.account_id"
                    :options="accountsOptions ? accountsOptions : []"
                    :loading="accountsOptionsLoading"
                    :disabled="accountsOptionsLoading"
                    optionValue="id"
                    optionLabel="note"
                    :placeholder="accountsOptionsLoading ? 'Загрузка аккаунтов...' : 'Выбрать аккаунт'"
                    :class="{ 'p-invalid' : apiKeyForm.errors.account_id }">
                    <template #option="slotProps">
                        <div class="p-dropdown-car-option">
                            <span>{{ slotProps.option.note }}</span>
                        </div>
                    </template>
                </Dropdown>
                <div v-if="apiKeyForm.errors.account_id" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.account_id }}</small></div>
            </div>
            <div class="field">
                <label for="apiKeyNote">Описание ключа</label>
                <Textarea
                    v-model="apiKeyForm.fields.note"
                    id="apiKeyNote"
                    placeholder="Введите текст"
                    rows="3"
                    cols="30"
                    :loading="showAccountLoading"
                    :autoResize="true"
                    :area-invalid="apiKeyForm.errors.note ? true : null"
                    :aria-describedby="apiKeyForm.errors.note ? apiKeyForm.errors.note : null"
                    :class="{ 'p-invalid' : apiKeyForm.errors.note }"/>
                <div v-if="apiKeyForm.errors.note" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.note }}</small></div>
            </div>
            <div class="field">
                <label for="publicKey">Public Key</label>
                <Textarea
                    v-model="apiKeyForm.fields.public_key"
                    id="publicKey"
                    placeholder="Введите public key"
                    rows="2"
                    cols="30"
                    :autoResize="true"
                    :area-invalid="apiKeyForm.errors.public_key ? true : null"
                    :aria-describedby="apiKeyForm.errors.public_key ? apiKeyForm.errors.public_key : null"
                    :class="{ 'p-invalid' : apiKeyForm.errors.public_key }"/>
                <div v-if="apiKeyForm.errors.public_key" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.public_key }}</small></div>
            </div>
            <div class="field">
                <label for="secretKey">Secret key</label>
                <Textarea
                    v-model="apiKeyForm.fields.secret_key"
                    id="secretKey"
                    placeholder="Введите Secret Key"
                    rows="2"
                    cols="30"
                    :autoResize="true"
                    :area-invalid="apiKeyForm.errors.secret_key ? true : null"
                    :aria-describedby="apiKeyForm.errors.secret_key ? apiKeyForm.errors.secret_key : null"
                    :class="{ 'p-invalid' : apiKeyForm.errors.secret_key }"/>
                <div v-if="apiKeyForm.errors.secret_key" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.secret_key }}</small></div>
            </div>
            <div class="field">
                <label for="color">Цвет для графика</label>
                <div>
                    <ColorPicker
                        id="color"
                        :default-color="defaultColor"
                        format="hex"
                        v-model="apiKeyForm.fields.color"/>
                </div>
                <div v-if="apiKeyForm.errors.color" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.color }}</small>
                </div>
            </div>
            <div class="field">
                <div class="mb-2">Активный</div>
                <InputSwitch
                    v-model="apiKeyForm.fields.is_active"
                    optionLabel="label"/>
                <div v-if="apiKeyForm.errors.is_active" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.is_active }}</small></div>
            </div>
            <div class="field">
                <div class="mb-2">Спотовый</div>
                <InputSwitch
                    v-model="apiKeyForm.fields.is_spot"
                    optionLabel="label"/>
                <div v-if="apiKeyForm.errors.is_spot" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.is_spot }}</small></div>
            </div>
            <div class="field">
                <div class="mb-2">Маржинальный</div>
                <InputSwitch
                    v-model="apiKeyForm.fields.is_margin"
                    optionLabel="label"/>
                <div v-if="apiKeyForm.errors.is_margin" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.is_margin }}</small></div>
            </div>
            <div class="field">
                <div class="mb-2">Фьючерсный</div>
                <InputSwitch
                    v-model="apiKeyForm.fields.is_futures"
                    optionLabel="label"/>
                <div v-if="apiKeyForm.errors.is_futures" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.is_futures }}</small></div>
            </div>
            <div class="field">
                <div class="mb-2">Только для чтения</div>
                <InputSwitch
                    v-model="apiKeyForm.fields.is_read_only"
                    optionLabel="label"/>
                <div v-if="apiKeyForm.errors.is_read_only" class="p-error text-left">
                    <small>{{ apiKeyForm.errors.is_read_only }}</small></div>
            </div>
            <template #footer>
                <ProgressBar
                    v-if="showApiKeyLoading"
                    mode="indeterminate"
                    class="custom-progress-bar"/>
                <template v-else>
                    <Button
                        label="Отмена"
                        icon="pi pi-times"
                        class="p-button-text"
                        @click="closeApiKeyDialog"/>
                    <Button
                        :loading="savingApiKeyLoading"
                        label="Сохранить"
                        icon="pi pi-check"
                        class="p-button-text"
                        @click="saveApiKey"/>
                </template>
            </template>
        </Dialog>
        <Dialog
            v-model:visible="accountDialog"
            :style="{width: '450px'}"
            :modal="true"
            header="Информация об аккаунте"
            class="p-fluid">
            <div class="field">
                <label for="accountNote">Описание аккаунта</label>
                <Textarea
                    v-model="accountForm.fields.note"
                    id="accountNote"
                    placeholder="Введите текст"
                    rows="5"
                    cols="30"
                    :autoResize="true"
                    :area-invalid="accountForm.errors.note ? true : null"
                    :aria-describedby="accountForm.errors.note ? accountForm.errors.note : null"
                    :class="{ 'p-invalid' : accountForm.errors.note }"/>
                <div v-if="accountForm.errors.note" class="p-error text-left">
                    <small>{{ accountForm.errors.note }}</small></div>
            </div>
            <div class="field">
                <div class="mb-2">Активный</div>
                <InputSwitch
                    v-model="accountForm.fields.is_active"
                    optionLabel="label"/>
                <div v-if="accountForm.errors.is_active" class="p-error text-left">
                    <small>{{ accountForm.errors.is_active }}</small></div>
            </div>
            <template #footer>
                <ProgressBar
                    v-if="showAccountLoading"
                    mode="indeterminate"
                    class="custom-progress-bar"/>
                <template v-else>
                    <Button
                        label="Отмена"
                        icon="pi pi-times"
                        class="p-button-text"
                        @click="closeAccountDialog"/>
                    <Button
                        :loading="savingAccountLoading"
                        label="Сохранить"
                        icon="pi pi-check"
                        class="p-button-text"
                        @click="saveAccount"/>
                </template>
            </template>
        </Dialog>
    </div>
</template>

<script>
import ApiKeyService from '../services/ApiKeyService'
import AccountService from '../services/AccountService'
import ToastMessageService from '../services/ToastMessageService'
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useConfirm } from 'primevue/useconfirm'
import FormService from '../services/FormService'

export default {
    setup () {
        const apiKeyService = new ApiKeyService()
        const accountService = new AccountService()
        const formService = new FormService()
        const toastMessageService = new ToastMessageService()
        const store = useStore()
        const confirmService = useConfirm()

        accountService.getAccountsWithApiKeys()

        const getAccountsWithApiKeys = computed(() => store.state.account.getAccountsWithApiKeys)
        const getAccountsWithApiKeysLoading = computed(() => store.state.account.getAccountsWithApiKeysLoading)
        const showAccountLoading = computed(() => store.state.account.showAccountLoading)
        const deleteAccountLoading = computed(() => store.state.account.deleteAccountLoading)
        const savingAccountLoading = computed(() => store.state.account.storeAccountLoading || store.state.account.updateAccountLoading)
        const showApiKeyLoading = computed(() => store.state.apiKey.showApiKeyLoading)
        const deleteApiKeyLoading = computed(() => store.state.apiKey.deleteApiKeyLoading)
        const savingApiKeyLoading = computed(() => store.state.apiKey.storeApiKeyLoading || store.state.apiKey.updateApiKeyLoading)
        const dataLoading = computed(() => getAccountsWithApiKeysLoading.value || deleteAccountLoading.value || deleteApiKeyLoading.value)
        const accountsOptions = computed(() => store.state.account.getAccounts)
        const accountsOptionsLoading = computed(() => store.state.account.getAccountsLoading)
        const selectedApiKey = computed(() => store.state.apiKey.showApiKey)
        const selectedAccount = computed(() => store.state.account.showAccount)
        const expandedRows = ref([])

        const accountActiveOptions = accountService.activeOptions()
        const apiKeyActiveOptions = apiKeyService.activeOptions()
        const defaultColor = apiKeyService.defaultColor()

        const accountDialog = ref(false)
        const apiKeyDialog = ref(false)

        const accountColumns = ref([
            { field: 'note', header: 'Описание аккаунта' },
            { field: 'exchange.name', header: 'Биржа' },
            { field: 'is_active', header: 'Статус' }
        ])
        const apiKeyColumns = ref([
            { field: 'color', header: 'Цвет', width: '5%' },
            { field: 'note', header: 'Описание ключа', width: '35%' },
            { field: 'public_key', header: 'Публичный ключ', width: '20%' },
            { field: 'secret_key', header: 'Секретный ключ', width: '20%' },
            { field: 'is_active', header: 'Статус', width: '10%' }
        ])

        const defaultAccountForm = () => {
            return {
                fields: {
                    id: null,
                    is_active: true,
                    note: null
                },
                errors: {}
            }
        }
        const defaultApiKeyForm = () => {
            return {
                fields: {
                    id: null,
                    is_active: true,
                    is_spot: true,
                    is_margin: true,
                    is_futures: true,
                    is_read_only: true,
                    note: null,
                    account_id: null,
                    color: defaultColor
                },
                errors: {}
            }
        }
        const apiKeyForm = reactive(defaultApiKeyForm())
        const accountForm = reactive(defaultAccountForm())
        const resetForm = () => {
            Object.assign(apiKeyForm, defaultApiKeyForm())
            Object.assign(accountForm, defaultAccountForm())
        }
        const openAccountDialog = () => {
            accountDialog.value = true
        }
        const openApiKeyDialog = (accountId = null) => {
            if (accountId) {
                apiKeyForm.fields.account_id = accountId
            }
            apiKeyDialog.value = true
            accountService.getAccounts()
        }
        const closeAccountDialog = () => {
            accountDialog.value = false
            resetForm()
        }
        const closeApiKeyDialog = () => {
            apiKeyDialog.value = false
            resetForm()
        }

        const showApiKey = async (event, id) => {
            openApiKeyDialog()
            await apiKeyService.showApiKey(id).then(() => {
                Object.assign(apiKeyForm.fields, { ...selectedApiKey.value })
            }).catch(() => accountService.getAccountsWithApiKeys())
        }
        const showAccount = async (id) => {
            openAccountDialog()
            await accountService.showAccount(id).then(() => {
                Object.assign(accountForm.fields, { ...selectedAccount.value })
            }).catch(() => accountService.getAccountsWithApiKeys())
        }

        const deleteApiKey = async (apiKeyId) => {
            const apiKey = apiKeyId || apiKeyForm.fields.id
            await apiKeyService.deleteApiKey(apiKey).then(() => {
                toastMessageService.add({
                    detail: 'Ключ удален'
                })
            }).catch().finally(() => {
                accountService.getAccountsWithApiKeys()
            })
        }

        const deleteAccount = async (accountId) => {
            const account = accountId || accountForm.fields.id
            await accountService.deleteAccount(account).then(() => {
                toastMessageService.add({
                    detail: 'Аккаунт удален'
                })
            }).catch().finally(() => {
                accountService.getAccountsWithApiKeys()
            })
        }

        const saveApiKey = async () => {
            try {
                if (apiKeyForm.fields.id) {
                    await apiKeyService.updateApiKey(apiKeyForm.fields.id, apiKeyForm.fields)
                } else {
                    await apiKeyService.storeApiKey(apiKeyForm.fields)
                }
                await toastMessageService.add({
                    detail: 'Ключ сохранен'
                })
                closeApiKeyDialog()
                await accountService.getAccountsWithApiKeys()
            } catch (exception) {
                formService.fillErrors(exception, apiKeyForm)
            }
        }
        const saveAccount = async () => {
            try {
                if (accountForm.fields.id) {
                    await accountService.updateAccount(accountForm.fields.id, accountForm.fields)
                } else {
                    await accountService.storeAccount(accountForm.fields)
                }
                await toastMessageService.add({
                    detail: 'Аккаунт сохранен'
                })
                closeAccountDialog()
                await accountService.getAccountsWithApiKeys()
            } catch (exception) {
                formService.fillErrors(exception, accountForm)
            }
        }

        const confirmDeleteAccount = (event, accountId) => {
            confirmService.require({
                target: event.currentTarget,
                message: 'Вы уверены что хотите удалить аккаунт?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deleteAccount(accountId)
                }
            })
        }

        const confirmDeleteApiKey = (event, apiKeyId) => {
            confirmService.require({
                target: event.currentTarget,
                message: 'Вы уверены что хотите удалить ключ?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    deleteApiKey(apiKeyId)
                }
            })
        }

        return {
            confirmDeleteAccount,
            confirmDeleteApiKey,
            apiKeyForm,
            accountForm,
            getAccountsWithApiKeys,
            dataLoading,
            accountDialog,
            apiKeyDialog,
            openAccountDialog,
            openApiKeyDialog,
            closeApiKeyDialog,
            closeAccountDialog,
            showApiKey,
            showAccount,
            deleteAccount,
            deleteApiKey,
            saveAccount,
            saveApiKey,
            showAccountLoading,
            showApiKeyLoading,
            accountsOptions,
            accountsOptionsLoading,
            accountActiveOptions,
            apiKeyActiveOptions,
            savingApiKeyLoading,
            savingAccountLoading,
            accountColumns,
            apiKeyColumns,
            expandedRows,
            defaultColor
        }
    }
}
</script>

<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-datatable) {
        &.p-datatable-customers {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr {
                border-bottom: 1px solid var(--surface-d);

                > td {
                    text-align: left;
                    display: block;
                    width: 100% !important;
                    float: left;
                    clear: left;
                    border: 0 none !important;

                    &:last-child {
                        text-align: center;
                    }

                    .p-column-title {
                        padding: .4rem;
                        min-width: 30%;
                        display: inline-block;
                        margin: -.4rem 1rem -.4rem -.4rem;
                        font-weight: bold;
                    }

                    .p-progressbar {
                        margin-top: .5rem;
                    }

                    .p-rating {
                        display: inline-block;
                    }
                }
            }
        }
    }

    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: .25rem;
        }
    }
}
</style>
